import { getJwtToken } from '@/services/apiWebToken';
import { useUserStore } from '@/store/userJWTStore'; // Import the Pinia store

declare let $: any;

export function setupJqueryOverides(baseUrl: string, csrfToken: string) {
  $.ajaxSetup({
    beforeSend: function (xhr: any, options: { url: string }) {
      options.url = baseUrl + options.url.replace('.php', '');
    },
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
    headers: {
      'X-XSRF-TOKEN': csrfToken,
    },
    global: true,
  });

  $.fn.submit = function () {
    console.log('submit');
    const form = this[0];
    //check if it is a form Element
    if (form && form.tagName === 'FORM') {
      this[0].dispatchEvent(new Event('overrideformsubmit'));
    }
  };

  // Listen for changes in the cookie, and update the store when it changes | Original code
  // function listenCookieChange() {
  //   let lastCookie = getCookieValue('SquashlevelsJWT'); // 'static' memory between function calls

  //   return function () {
  //     let currentCookie = getCookieValue('SquashlevelsJWT');

  //     if (currentCookie != lastCookie) {
  //       // something useful like parse cookie, run a callback fn, etc.
  //       console.log('Cookie Changed');
  //       store.dispatch('User/SetJWT', currentCookie);

  //       lastCookie = currentCookie; // store latest cookie
  //       currentCookie = getCookieValue('SquashlevelsJWT');
  //     }
  //   };
  // }

  // Listen for changes in the cookie, and update the store when it changes | New code using Pinia
  function listenCookieChange() {
    let lastCookie = getCookieValue('SquashlevelsJWT');
    const userStore = useUserStore(); // Initialize the Pinia store

    return function () {
      let currentCookie = getCookieValue('SquashlevelsJWT');

      if (currentCookie !== lastCookie && currentCookie !== undefined) {
        console.log('Cookie Changed');
        userStore.fetchToken(currentCookie); // Ensuring currentCookie is defined before passing

        lastCookie = currentCookie;
      }
    };
  }

  function getCookieValue(name: string) {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
      return decodeURIComponent(match[2]);
    }
  }

  window.setInterval(listenCookieChange(), 100);
}
